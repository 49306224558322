<template>
  <ModuleComponent
    ref="moduleComponent"
    @changed="item = $event"
    :module-data="moduleData"
    @beforeSaveItem="beforeSaveItem"
    @beforeItemLoad="beforeItemLoad"
  >
    <template v-slot:tableFilters="{ filters }">
      <b-container fluid>
        <b-row>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.COUNTRY')" class="mb-3">
              <v-select2
                v-model="filters.countryId.value"
                endPoint="locations"
                :placeholder="t('GLOBAL.COUNTRY')"
                sort="order"
                :filters="{ type: { value: 0 } }"
              ></v-select2>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:form="{ item }">
      <b-tabs class="mt-3">
        <b-tab :title="t('GLOBAL.BASEPRICE')" active>
          <b-row>
            <b-col cols="6" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.COUNTRY')" class="mb-3">
                <v-select2
                  v-model="item.countryId"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.COUNTRY')"
                  sort="order"
                  :filters="{ type: { value: 0 } }"
                  :name="t('GLOBAL.COUNTRY')"
                  v-validate.continues="'required'"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="6" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.STARTDATE')">
                <BDatePicker
                  :id="'startDate'"
                  v-model="item.startDate"
                  @input="
                    item.startDate = $event;
                    item.endDate = $event;
                  "
                  hide-header
                  show-decade-nav
                  :placeholder="t('GLOBAL.STARTDATE')"
                  :name="t('GLOBAL.STARTDATE')"
                  v-validate.continues="'required'"
                >
                </BDatePicker>
              </b-form-group>
            </b-col>
            <b-col cols="6" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.ENDDATE')">
                <BDatePicker
                  :id="'endDate'"
                  v-model="item.endDate"
                  @input="item.endDate = $event"
                  hide-header
                  show-decade-nav
                  :placeholder="t('GLOBAL.ENDDATE')"
                  :name="t('GLOBAL.STARTDATE')"
                  v-validate.continues="'required'"
                >
                </BDatePicker>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="t('GLOBAL.PRODUCTPRICES')">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ t("GLOBAL.PRODUCT") }}</th>
                  <th class="text-left">{{ t("GLOBAL.PRODUCTKIND") }}</th>
                  <th class="text-center">{{ t("GLOBAL.PICKINGPRICE") }}</th>
                  <th class="text-center">{{ t("GLOBAL.PACKINGPRICE") }}</th>
                  <th class="text-center">{{ t("GLOBAL.CONSULTINGPRICE") }}</th>
                  <th class="text-center">{{ t("GLOBAL.RATEOFWASTE") }}</th>
                  <th class="text-center">{{ t("GLOBAL.FEE") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in productPricesMap" :key="p.productKindId">
                  <td>{{ p.product ? p.product.name : "" }}</td>
                  <td>{{ p.productKind ? p.productKind.name : "" }}</td>
                  <td class="text-center">
                    <b-form-group>
                      <b-input-group>
                        <b-form-input
                          type="number"
                          v-model="p.pickingPrice"
                          step="0.01"
                          placeholder="0.00"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-input-group-text>TL</b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </td>
                  <td class="text-center">
                    <b-form-group>
                      <b-input-group>
                        <b-form-input
                          type="number"
                          v-model="p.packingPrice"
                          step="0.01"
                          placeholder="0.00"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-input-group-text>TL</b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </td>
                  <td class="text-center">
                    <b-form-group>
                      <b-input-group>
                        <b-form-input
                          type="number"
                          v-model="p.consultingPrice"
                          step="0.01"
                          placeholder="0.00"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-input-group-text>TL</b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </td>
                  <td class="text-center">
                    <b-form-group>
                      <b-input-group>
                        <b-form-input
                          type="number"
                          v-model="p.rateOfWaste"
                          step="0.1"
                          placeholder="0.0"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-input-group-text>%</b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </td>
                  <td class="text-center">
                    <b-form-group>
                      <b-input-group>
                        <b-form-input
                          type="number"
                          v-model="p.fee"
                          step="0.01"
                          placeholder="0.00"
                        ></b-form-input>
                        <b-input-group-append
                          >accountAddress
                          <b-input-group-text>TL</b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </b-tab>
      </b-tabs>
    </template>
  </ModuleComponent>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Role
export default {
  name: "basePrices",
  data() {
    return {
      item: {},
      moduleData: {
        name: "basePrices",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: false,
          graphqlQuery: `
                          id,
                          country{name},
                          startDate,
                          endDate
                        `,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.COUNTRY"), value: "country.name" },
            { text: this.t("GLOBAL.STARTDATE"), value: "startDate" },
            { text: this.t("GLOBAL.ENDDATE"), value: "endDate" }
          ],
          customColumns: [],
          filters: {
            countryId: {
              type: "number",
              value: null
            }
          }
        }
      },
      allProducts: [],
      allProductKinds: [],
      productPricesMap: []
    };
  },
  methods: {
    beforeSaveItem(item) {
      item.productPrices = this.productPricesMap.map(p => {
        delete p["product"];
        delete p["productKind"];
        return p;
      });
    },
    beforeItemLoad(item) {
      this.productPricesMap = this.getProductPrices(item);
    },
    getProductPrices(item) {
      let productPrices = [];
      for (const i in this.allProducts) {
        let product = this.allProducts[i];
        for (const j in this.allProductKinds) {
          let productKind = this.allProductKinds[j];
          if (productKind.productId == product.id) {
            let lineFound = false;
            for (const k in item.productPrices) {
              if (
                item.productPrices[k].productId == product.id &&
                item.productPrices[k].productKindId == productKind.id
              ) {
                productPrices.push(item.productPrices[k]);
                lineFound = true;
                break;
              }
            }
            if (!lineFound) {
              productPrices.push({
                basePriceId: this.item.id,
                productId: product.id,
                productKindId: productKind.id,
                product: {
                  name: product.name
                },
                productKind: {
                  name: productKind.name
                },
                pickingPrice: 0,
                packingPrice: 0,
                consultingPrice: 0,
                rateOfWaste: 0,
                fee: 0
              });
            }
          }
        }
      }
      return productPrices;
    }
  },
  mounted() {
    let vm = this;
    this.apiSendGraph("products", "id,name", "name").then(data => {
      vm.allProducts = data.data;
    });
    this.apiSendGraph("productKinds", "id,name,productId", "name").then(
      data => {
        vm.allProductKinds = data.data;
      }
    );
  }
};
</script>
